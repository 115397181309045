.discounts {
  font-size: 1.2rem;
}

.discounts__discount {
  display: flex;
  align-items: center;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
}

.discounts__discount svg {
  color: rgba(var(--color-button), var(--alpha-button-background));
}

.discounts__discount--position {
  justify-content: center;
}

@media screen and (min-width: 750px) {
  .discounts__discount--position {
    justify-content: flex-end;
  }
}

.discounts__discount > .icon {
  color: rgb(var(--color-foreground));
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.7rem;
}
